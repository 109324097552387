// 我的应用
// mxy
<template>
  <div class="box flex-row justify-center">
    <div class="iview flex-column" :style="{ minHeight: minHeight + 'px' }">
      <page-head title="我的应用" />
      <a class="goProduct" href="/ProductCentre">去产品中心</a>
      <a-spin :spinning="spinning">
        <a-empty description="暂无数据" v-if="list.length == 0" />
        <div class="application">
          <div
            @click="onTrial(item)"
            class="card"
            v-for="item in list"
            :key="item"
          >
            <div class="right_top">
              <!-- <img
                v-if="item.isOwn"
                src="@/assets/iocn_wujiaoxin.png"
                alt=""
              /> -->
              <AppQRCode v-if="item.overdue == false && item.miniPro"
                :pictureUrl="item.miniPro" />
            </div>
            <img :src="item.applicationIcon" alt="" class="logo" />
            <div class="name ellipsis" :title="item.applicationName">
              {{ item.applicationName }}
            </div>
            <a-tag color="red" v-if="item.overdue === true"> 试用到期 </a-tag>
            <a-tag color="red" v-if="item.cautionLevel === 1">
              3天后到期
            </a-tag>
            <a-tag color="red" v-if="item.cautionLevel === 2">
              2天后到期
            </a-tag>
            <a-tag color="red" v-if="item.cautionLevel === 3">
              1天后到期
            </a-tag>
            <a-tag color="red" v-if="item.cautionLevel === 4">
              12小时后到期
            </a-tag>
            <!-- <div style="margin-top: 8px">
              <a-tag
                color="blue"
                v-if="
                  item.needUpdate === true && item.versionUpdating === false
                "
              >
                有更新版本
              </a-tag>
              <a-tag color="blue" v-if="item.versionUpdating === true">
                正在更新
              </a-tag>
            </div> -->
          </div>
        </div>
      </a-spin>
      <a-modal
        title="到期提醒"
        :visible="overdueVisible"
        :confirm-loading="confirmLoading"
        cancelText="取消"
        okText="确认"
        :maskClosable="false"
        @ok="overdueCancel"
        @cancel="overdueCancel"
      >
        <div>
          <div class="overdue-title">{{ titleList.applicationName }}</div>

          <div>
            <span> 试用到期，请点击 </span>
            <span class="buy font-size" @click="buy()">联系购买</span>
            或拨打电话
            <span class="font-size">15862885968</span>
          </div>
          <!-- <span>{{ titleList.expireTime }}</span>
        <span>—</span>
        <span>{{ titleList.expireTime }}</span>
        <div>试用{{titleList.trialValidity}}天,</div> -->
        </div>
      </a-modal>
      <a-modal
        title="购买信息"
        :visible="visible"
        cancelText="取消"
        okText="确认"
        @ok="handleOk"
        :maskClosable="false"
        @cancel="handleCancel"
        :confirm-loading="confirmLoading"
      >
        <a-spin :spinning="spinning">
          <a-form
            :model="register"
            ref="formRef"
            :rules="rules"
            autocomplete="off"
            :labelCol="{ span: 6 }"
            :wrapperCol="{ span: 18 }"
          >
            <a-form-item required name="name" label="姓名">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.name"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
            <a-form-item required name="phoneNumber" label="手机号">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="11"
                type="text"
                v-model:value="register.phoneNumber"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
            <a-form-item required name="companyName" label="企业名称">
              <a-input
                style="width: 80%"
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.companyName"
                placeholder="请输入"
              >
              </a-input>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>
      <!-- 扫码跳转小程序 -->
      <a-modal
        title="您可以通过微信扫码访问"
        :visible="showMobileModal"
        :destroyOnClose="true"
        :footer="null"
        @cancel="closeMobileModal"
      >
        <!-- <div>你可以通过微信扫码访问</div> -->
        <div>
          <div id="ApplicationQRCode" class="QRcode_box">
            <!-- <ApplicationQRCode :item="mobileApplication" /> -->
            <img :src="mobilePicUrl" style="width: 150px;height: 150px;" alt="小程序">
          </div>
          <!-- <div class="mobileApplicationName">{{mobileApplicationName}}</div> -->
        </div>
        
      </a-modal>
    </div>
  </div>
</template>

<script>
import { VersionDue, NotIbpsAppliationUrl } from '@/assets/common.js'
import { defineComponent } from 'vue'
import AppQRCode from '@/components/AppQRCode'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
// import ApplicationQRCode from '@/components/ApplicationQRCode'
import PageHead from '@/components/PageHead'
import { getProfileDetail } from '@/api/IdentityAPI'
// import { EditOutlined, MoreOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import { getEnvVars } from '@/api/config'

export default defineComponent({
  components: {
    'page-head': PageHead,
    AppQRCode
    // ApplicationQRCode
    // EditOutlined,
    // MoreOutlined,
    // MinusCircleOutlined,
    // FullscreenOutlined,
    // 'usage-record': UsageRecord,
  },
  data () {
    return {
      list: [],
      spinning: false,
      popover: false,
      visible: false,
      overdueVisible: false,
      confirmLoading: false,
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],
        companyName: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      titleList: {},
      titleListTime: '',
      register: {
        name: '',
      },
      showMobileModal: false,
      mobileApplication: {},
      mobileApplicationName: '',
      qrImg_url: '' // 小程序
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
  },
  mounted () {
    this.getMenu()
    // this.application()
  },
  // 方法
  methods: {
    getMenu () {
      this.spinning = true
      api
        .get('/api/app/sys-application-on/application-and-menu?menuType=1')
        .then(({ data }) => {
          // this.list = data
          // 0 小程序 1 Web网页 2 移动端App  3 移动端网页
          const res = data
          res.forEach(i => {
            if (i.urls) {
              const arr = i.urls.map(k => {
                if (k.applicationType === 0) {
                  i.miniPro = k.pictureUrl
                }
                return k.applicationType
              })
              let str = '端口 : '
              if (!i.isNotIbps) { // 低代码应用，urls数组是空的，会直接通过点击打开按钮跳转web网页
                str += 'PC端'
                i.onlyMobile = false
              } else {
                if (arr.includes(1)) { // 非低代码应用，urls数组肯定有内容，应用类型是1就是web网页，0就是微信小程序
                  str += 'PC端'
                  if (arr.includes(0)) {
                    str += ' / 移动端'
                  }
                  i.onlyMobile = false
                } else if (arr.includes(0)) {
                  str += '移动端'
                  i.onlyMobile = true // 该应用纯属移动端
                }
              }
              i.tip = str
            } else { // urls === null
              i.tip = '端口 : PC端'
              i.onlyMobile = false
            }
          });
          this.list = res
          console.log('整合后的list', res);
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          console.log(err)
          // this.$message.error('获取失败' + err)
        })
    },
    clickScanBtn(item) { // 扫码按钮点击事件
      this.mobilePicUrl = item.miniPro
      this.showMobileModal = true
    },
    closeMobileModal() {
      this.showMobileModal = false;
      this.mobilePicUrl = ''
    },
    onTrial (item) {
      console.log('item', item)
      // 待确定
      // if (item.versionUpdating === true) {
      //   api
      //     .get('/api/app/sys-application-on/application-complete', {
      //       params: {
      //         id: item.applicationId,
      //       },
      //     })
      //     .then(({ data }) => {
      //       const completeDate = data
      //       if (completeDate.versionUpdating === true) {
      //         this.$message.warn('当前菜单正在更新')
      //         return
      //       }
      //       const routeData = this.$router.resolve({ path: '/ApplicationDetails', query: { id: item.id } })
      //       window.open(routeData.href, '_blank')
      //     })
      //     .catch(err => {
      //       console.log(err)
      //       this.spinning = false
      //       // this.$message.error('获取失败' + err)
      //     })
      //   return
      // }
      if (item.overdue === false) {
        if (VersionDue()) {
          this.$message.warning('当前企业版本已过期，请联系客服人员')
          return
        }
        if (item.isNotIbps) { // 非低代码平台应用
          if (item.onlyMobile) { // 仅仅是小程序的情况下，弹框提示扫描二维码
            this.clickScanBtn(item)
            return
          }
          //  若为非低代码平台应用，item.urls肯定不为空
          //  若应用类型为1，就是web网页，PC端可以跳转
          let url = item.urls.find(e => e.applicationType === 1).url
          url = NotIbpsAppliationUrl(url)
          window.open(url, '_blank')
          this.spinning = false
          return
        }
        if (item.isComplete === false) {
          api
            .get('/api/app/sys-application-on/application-complete', {
              params: {
                id: item.applicationId,
              },
            })
            .then(({ data }) => {
              const completeDate = data
              if (completeDate.isComplete === false) {
                this.$message.warn('当前菜单还未创建完成')
                return
              } else {
                if (item.authResList.length === 0) {
                  this.$message.warn('当前应用无可用菜单')
                  return
                }
              }
              // const routeData = this.$router.resolve({ path: '/ApplicationDetails', query: { id: item.id } })
              const routeData = this.$router.resolve({ path: `/ApplicationDetails/${item.id}` })
              window.open(routeData.href, '_blank')
            })
            .catch(err => {
              console.log(err)
              this.spinning = false
              // this.$message.error('获取失败' + err)
            })
        } else {
          if (item.authResList.length === 0) {
            this.$message.warn('当前应用无可用菜单')
            return
          }
          // const routeData = this.$router.resolve({ path: '/ApplicationDetails', query: { id: item.id } })
          const routeData = this.$router.resolve({ path: `/ApplicationDetails/${item.id}` })
          window.open(routeData.href, '_blank')
        }
      } else {
        // console.log(item)
        this.overdueVisible = true
        this.titleList = item
        this.titleListTime = dayjs(item.expireTime).format('YYYY-MM-DD HH:mm:ss')
        getProfileDetail().then(data => {
          if (data.status === 1) {
            this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
            window.location.href = '/account/login'
            window.localStorage.clear()
          }
          this.register.name = data.name
          this.register.phoneNumber = data.phoneNumber
        })
        const tenantList = JSON.parse(localStorage.getItem('tenantList'))
        this.register.companyName = tenantList.enterpriseName
      }
    },
    closeMobileModal() {
      this.showMobileModal = false;
      // this.mobileApplicationName = ''
      // this.mobileApplication = null
      this.qrImg_url = ''
    },
    // notTrial (item) {
    //   if (item.isComplete === false) {
    //     this.$message.warn('数据库未创建完成，请稍后在试')
    //     return
    //   }

    // },
    // 确定
    handleOk () {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        this.spinning = true
        const obj = {
          name: this.register.name,
          phone: this.register.phoneNumber,
          companyName: this.register.companyName,
          versionName: this.titleList.applicationName,
        }
        api
          .post('/api/app/message/purchase-version', obj)
          .then(({ data }) => {
            this.confirmLoading = false
            this.spinning = false
            this.$message.success('您已成功提交购买信息！')
            this.handleCancel()
            this.overdueCancel()
            this.overdueVisible = false
          })
          .catch(err => {
            this.confirmLoading = false
            this.spinning = false
            // this.$message.error('创建失败' + err)
            console.log(err)
          })
      })
    },
    // 取消
    handleCancel () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
    // 联系购买
    buy () {
      this.visible = true
    },
    // 到期提醒框取消
    overdueCancel () {
      this.overdueVisible = false
    },
    editClick () {
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.box {
  width: 100%;
  height: 100%;
}
.iview {
  width: 82%;
  margin: 24px 0;
  background-color: #fff;
  position: relative;
  .goProduct {
    position: absolute;
    top: 24px;
    right: 30px;
    color: #134ccf;
  }
  .application {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .card {
    width: 200px;
    height: 200px;
    margin: 10px 38px 48px 38px;
    text-align: center;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    padding: 10px 24px;
    position: relative;

    &:hover {
      margin-top: -3px;
      border-radius: 2%;
      background-color: #eee;
      // box-shadow: 5px 5px 5px 5px rgba(0.1, 0.1, 0.1, 0.1);
    }
  }
  .btn {
    position: absolute;
    top: 14px;
    right: 20px;
  }
  .logo {
    width: 80px;
    height: 80px;
  }
  .name {
    font-size: 16px;
    margin: 10px 0;
  }
  .introduce {
    margin: 10px 0 20px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .outlined {
    position: absolute;
    top: 20px;
    right: 10px;
  }
  .action {
    span {
      margin: 0 8px;
    }
  }
  .popover {
    position: absolute;
    right: 30px;
    top: 6px;
  }
  .ant-row {
    margin: 30px 0;
    padding: 0 30px;
  }
  // .card {
  //   margin: 0 16px;
  //   height: 200px;
  // }
  .cardDefault {
    margin: 0 16px;
    height: 200px;
    cursor: default;
  }
  .card .popover {
    display: none;
  }
  .card:hover .popover {
    display: block;
  }
}
.overdue-title {
  // color: #1890ff;
  font-size: 20px;
  margin-bottom: 10px;
}
.buy {
  cursor: pointer;
}
::v-deep(.ant-empty) {
  margin: 95px 8px;
}
::v-deep(.ant-card-body) {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}
::v-deep(.ant-col) {
  padding: 0;
  margin-bottom: 10px;
}
.ant-tag {
  margin-right: 0;
}

.QRcode_box {
  width: 180px;
  margin: 0 auto;
}
.mobileApplicationName {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  color: #2B60D9;
}
.right_top {
  position: absolute;
  right: 10px;
  top: 0;
}
</style>
