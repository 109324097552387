// 页头
// 目前  企业管理，用户信息管理一系列页面在用
<template>
  <div class="head">{{title}}</div>
  <div class="line_h"></div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
  },

})
</script>
<style  lang="scss" scoped>
@import "@/assets/common.scss";

.head {
  letter-spacing: 1px;
  background: #fff;
  padding: 0px 20px;
  height: 60px;
  font-size: 18px;
  line-height: 60px;
  border-radius: 3px 3px 0 0;
}
</style>
