// 二维码
// sle
<template>
  <a-dropdown placement="bottomRight">
    <div class="rightIcon flex-centent">
      <img class="icon" :src="imgPath + '39fdd8e1-f927-3be1-af35-f929a70cbf6e/scan_logo.svg'" alt="" />
    </div>
    <template #overlay>
      <div class="main flex-column">
        <!-- <div class="title flex-centent">
          <span class="text">你 可以通过以下方式访问</span>
        </div> -->
        <div class="bottom flex-column justify-around">
          <div class="left flex justify-around align-around">
            <!-- <img src="@/assets/ewm2.png" style="width: 120px;height:120px;" alt="威智造小程序" /> -->
            <img :src="pictureUrl" style="width: 120px;height:120px;" alt="小程序" />
            <!-- <div class="text flex-centent">小程序</div> -->
          </div>
          <div class="title flex-centent">
            <span class="text">打开微信扫一扫,登陆小程序</span>
          </div>
          <!-- <div class="right flex-column justify-around align-around">
            <img class="qcCode" :src="officialAccounts" alt="威智造公众号" />
            <div class="text flex-centent">威智造公众号</div>
          </div> -->
        </div>
      </div>
    </template>
  </a-dropdown>
</template>
<script>
import { defineComponent } from 'vue'
import { getEnvVars } from '@/api/config'
const { imgUrl } = getEnvVars()
export default defineComponent({
  props: {
    pictureUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      imgPath: imgUrl,
      // officialAccounts: 'https://wservice.yaweicloud.com/iconfont/39feda87-b2a4-2201-30c7-97c8d13543cd/威平台公众号.png', // 威智造公众号
    }
  },
  created() {
    console.log('this.imgPath', this.imgPath)
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.icon {
  // margin: 15px 13px;
  width: 18px;
  height: 18px;
  color: white;
  // cursor: pointer;
  // background-color: red;
}

.main {
  // box-shadow: 2px 2px 2px 2px #bebdbd;
  // box-shadow: 2px 2px 2px 2px #eeeeee;
  box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);
  border-radius: 5px;
  height: 220px;
  width: 200px;
  padding-top: 30px;
  background: #ffff;
  .title {
    // height: 45px;
    margin-top: 20px;
    color: #999999;
    font-size: 12px;
    width: 100%;
  }
  .bottom {
    // height: 154px;
    // width: 100%;
    .qcCode {
      height: 129px;
      width: 129px;
    }
  }
}
</style>
